html[data-theme='dark'],
html[data-theme='null'],
html[data-theme='false'] {
  --background-color: #181c2b;
  --loader-mask: #00000073;
  --site-header-color: #21253b;
  --section-header-color: #24283d;
  --light-grey-color: #181c2b;
  --light-green-color: #1ed994;
  --light-red-color: #f24b78;
  --dark-yellow-color: #f2ed49;
  --primary-color: #1f2336;
  --white-color: #ffffff;
  --always-white-color: #ffffff;
  --light-blue-color: #008ccf;
  --light-blue-color-2: #49c2f2;
  --border-color: #4e5266;
  --select-active-color: rgba(20, 146, 230, 0.1);
  --primary-blue: #005dfb;
  --text-color: #aaa;
}
