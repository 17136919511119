.ant-dropdown.ant-dropdown-placement-bottomLeft
  .announcement-title
  > div:nth-child(2) {
  display: block !important;
}

.ant-dropdown.ant-dropdown-placement-bottomLeft
  .announcement-title
  > div:nth-child(1) {
  font-weight: 700;
}
