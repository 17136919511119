html[data-theme='light'] {
  --background-color: #e9eced;
  --loader-mask: transparent;
  --site-header-color: #dff2fb;
  --section-header-color: #dff2fb;
  --light-grey-color: #181c2b;
  --light-green-color: #1ed994;
  --light-red-color: #f24b78;
  --dark-yellow-color: #f2ed49;
  --primary-color: #ffffff;
  --white-color: #12316f;
  --always-white-color: #ffffff;
  --light-blue-color: #008ccf;
  --light-blue-color-2: #49c2f2;
  --border-color: #c3cbdb;
  --select-active-color: rgba(20, 146, 230, 0.1);
  --primary-blue: #dff2fb;
}
