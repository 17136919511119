@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  background: var(--background-color) !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

html {
  font-size: 62.5%;
  font-family: 'Noto Sans Display', sans-serif !important;
  overflow-x: hidden;
  background: var(--background-color);
}

button {
  border: none;
  outline: none;
  background: transparent;
  padding: 0;
}

body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul,
li {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

hr:not([size]) {
  height: 0;
}

body > iframe {
  display: none !important;
}

strong {
  font-weight: bold;
}

body::-webkit-scrollbar {
  width: 12px; /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
  background: var(--background-color); /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
  background-color: var(--border-color); /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: 3px solid var(--primary-color); /* creates padding around scroll thumb */
}

body {
  scrollbar-width: thin; /* "auto" or "thin" */
  scrollbar-color: var(--border-color) var(--primary-color); /* scroll thumb and track */
}

*::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--border-color) var(--primary-color);
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 6px;
  height: 5px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
}

*::-webkit-scrollbar-track {
  background-color: var(--primary-color);
}

*::-webkit-scrollbar-thumb {
  background-color: var(--border-color);
  border-radius: 10px;
  border: 3px solid transparent;
  -webkit-border-radius: 10px;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

body.modal-open {
  overflow: hidden;
  position: fixed;
}

body {
  overflow-x: hidden;
}

.ant-typography h5,
h5.ant-typography {
  color: var(--white-color) !important;
}

button.cancel-btn {
  background: transparent !important;
  border: 1px solid var(--border-color) !important;
}

.ant-table-tbody > tr.ant-table-placeholder:hover > td {
  background: transparent !important;
}

.ant-input,
.ant-select-selector {
  padding: 2px 10px !important;
}

.ant-select-item-option-disabled.ant-select-item-option-selected {
  background-color: var(--select-active-color) !important;
}

.ant-select-single .ant-select-selector .ant-select-selection-search {
  left: 0 !important;
}

/* -------------------------------------------------------------------------- */
/*                             STYLE INPUT GLOBAL                             */
/* -------------------------------------------------------------------------- */


/* -------------------------------------------------------------------------- */
/*                                RELOGIN MODAL                               */
/* -------------------------------------------------------------------------- */
.relogin-modal {
  margin: 0 auto;
}

.relogin-modal p {
  display: flex;
  justify-content: center;
  margin: 2rem 0;
  color: var(--white-color);
}

.relogin-modal .ant-modal-confirm-body {
  display: flex !important;
  flex-direction: column !important;
}

.relogin-modal .ant-modal-confirm .ant-modal-confirm-btns {
  margin-top: 12px;
  text-align: center;
}

.relogin-modal .ant-modal-confirm-body-wrapper {
  display: flex;
  flex-direction: column;
}

.ant-modal-confirm-body-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.ant-modal-confirm-body {
  padding: 20px;
}
.ant-modal-confirm-title,
.ant-modal-confirm-content {
  color: #fff !important;
}

.ant-modal-confirm .ant-modal-confirm-btns {
  margin-top: 0 !important;
  margin-bottom: 24px;
}

.ant-modal-confirm-btns button {
  background: var(--background-color);
}

.anticon {
  vertical-align: middle !important;
}

/* -------------------------------------------------------------------------- */
/*                                 EMPTY DATA                                 */
/* -------------------------------------------------------------------------- */
.ant-empty-description {
  color: var(--white-color) !important;
  font-weight: 700;
}

.ant-empty-normal {
  color: var(--white-color) !important;
  font-weight: 700;
}

.ant-dropdown .time-dropdown {
  display: block;
  align-self: flex-start;
}

.ant-dropdown .text-dropdown {
  display: block;
  white-space: inherit;
}

.ant-table-tbody > tr.ant-table-placeholder:hover > td {
  background: var(--primary-color) !important;
}

.empty-data-table .ant-empty-normal {
  margin: 100px 0;
}

.empty-data-table > tr.ant-table-placeholder > td {
  border-bottom: 1px solid transparent !important;
}

/* -------------------------------------------------------------------------- */
/*                             STYLE GLOBAL TABLE                             */
/* -------------------------------------------------------------------------- */
.table-global .ant-table-thead > tr > th {
  color: var(--white-color) !important;
  background: var(--primary-color) !important;
  border-bottom: 1px solid var(--border-color) !important;
}

.table-global .ant-table-tbody > tr > td {
  border-bottom: 1px solid var(--border-color) !important;
  background: var(--primary-color) !important;
  color: var(--white-color) !important;
}

.table-global .ant-table-tbody > tr:last-child > td {
  border-bottom: 1px solid transparent !important;
}

.table-global .ant-table-tbody > tr.ant-table-row:hover > td {
  background: var(--section-header-color) !important;
  cursor: pointer;
}

.table-global .ant-table-container,
.table-global .ant-table-container table > thead > tr:first-child th:first-child {
  border-top-left-radius: 0 !important;
}

.ant-table-container table > thead > tr:first-child th:last-child {
  border-top-right-radius: 0 !important;
}

.table-global .ant-table-column-sorter {
  margin-left: 4px;
  color: #bfbfbf;
  font-size: 0;
  transition: color 0.3s;
  padding-top: 3px;
}

.table-global .ant-table-cell-scrollbar {
  display: none;
}

.stock-detail-modal .stock-detail {
  max-height: calc(100vh - 14rem) !important;
}

.stock-detail-modal .stock-detail > .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
.eiLUgz .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
  width: 45% !important;
}

.ant-btn.ant-btn-primary {
  color: var(--always-white-color) !important;
  background: var(--primary-blue);
  border-color: var(--primary-blue);
}

/* -------------------------------------------------------------------------- */
/*                             GLOBAL NOTIFICATION                            */
/* -------------------------------------------------------------------------- */
.ant-notification-notice {
  background: var(--light-green-color) !important;
  color: var(--white-color) !important;
  border: 1px solid var(--border-color) !important;
  font-weight: 700;
}

.public-DraftEditor-content .public-DraftStyleDefault-block {
  margin: 0;
}
.search-stock-autocomplete {
  min-width: 320px !important;
  padding: 0;
  /* border-radius: 12px; */
  /* background-color: #212335 !important; */
}

.search-stock-autocomplete .rc-virtual-list {
  padding: 8px;
  background: #1c1f2f;
  border: 1px solid #6b697a;
  border-radius: 6px;
}

.search-stock-autocomplete .rc-virtual-list-scrollbar {
  background: rgba(26, 28, 43, 0.42);
}

.search-stock-autocomplete .rc-virtual-list-scrollbar-thumb {
  background: #3d3c41 !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--primary-blue) !important;
  border-color: var(--primary-blue) !important;

  &:after {
    border-color: black;
  }
}

.ant-select-dropdown {
  padding: 0;
}

.success-noti-copytrade.ant-notification-notice {
  background: #1f2336;
}
.success-noti-copytrade.ant-notification-notice
  .ant-notification-notice-message {
  color: #fff;
}
.success-noti-copytrade.ant-notification-notice
  .ant-notification-notice-description {
  color: #908f99;
}
